/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { ASMRModelChange } from '@/store/asmr/types';
const namespace: string = 'asmr';

export default class AsmrVideoChosenPage extends Vue {
	@State('asmr') asmr: ASMRModelChange | undefined;
	@Getter('getModelItem', {namespace}) getModelItem: any;
	@Getter('getArrVideo', {namespace}) getArrVideo: any;
	@Getter('getPriceVideo', {namespace}) getPriceVideo: any;
	@Action('getAsmrModelsVideo', {namespace}) getAsmrModelsVideo: any;

	videoChoisen() {
		this.$router.push({ name: 'asmr-video-viewing' }).then(r => r);
	}

	selectedVideo() {
		this.$router.push({ name: 'asmr-video-chosen' }).then(r => r);
	}
}
